<template>
  <div class="user-profile-engagement">
    <v-row>
      <v-col cols="12">
        <engagement-rate
          :user-data="userData"
          :engagement-data="engagementData"
        />
      </v-col>

      <v-col cols="12">
        <engagement-spread
          :engagement-data="engagementData"
        />
      </v-col>

      <v-col
        v-if="userData.data.user_profile.top_hashtags"
        cols="12"
        lg="6"
      >
        <popular-tags
          :user-data="userData"
        />
      </v-col>

      <v-col
        v-if="userData.data.extra.social_sentiment && userData.data.extra.social_sentiment.score && userData.data.extra.social_sentiment.distribution"
        cols="12"
        lg="6"
      >
        <social-sentiment
          :user-data="userData"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Import child components
const EngagementSpread = () => import(/* webpackChunkName: "blocks-engagement-spread" */ "@/blocks/user-profile/engagement/EngagementSpread.vue")
const SocialSentiment = () => import(/* webpackChunkName: "blocks-social-sentiment" */ "@/blocks/user-profile/engagement/SocialSentiment.vue")
const EngagementRate = () => import(/* webpackChunkName: "blocks-engagement-rate" */ "@/blocks/user-profile/engagement/EngagementRate.vue")
const PopularTags = () => import(/* webpackChunkName: "blocks-popular-tags" */ "@/blocks/user-profile/engagement/PopularTags.vue")

// Export the SFC
export default {
  // Name of the component
  name: "Engagement",

  // Accept incoming data from parent
  props: {
    platform: {
      type: String,
      required: true,
    },

    userData: {
      type: Object,
      required: true
    },

    engagementData: {
      type: Object,
      required: true
    }
  },

  // Register the components
  components: {
    EngagementSpread,
    SocialSentiment,
    EngagementRate,
    PopularTags
  },
}
</script>
